import styled from "@emotion/styled";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from "react";
import Video, {
  LocalVideoTrack,
  Participant as TwilioParticipant,
  RemoteParticipant,
  createLocalAudioTrack
} from "twilio-video";
import {
  GaussianBlurBackgroundProcessor,
  Pipeline
} from "@twilio/video-processors";
import { DateTime } from "luxon";

import { useSelector } from "react-redux";
import { useWakeLock } from "react-screen-wake-lock";
import { CircularProgress, Typography } from "@mui/material";

import { RootState, useAppDispatch } from "../../redux";
import TwilioVideoCallHandleType from "../../components/twilio/TwilioVideoCallHandleType";
import { CopilotIQLogo } from "../../assets/index.web";

import Track from "./Track";
import Participant from "./Participant";

import EnvVars from "../../config/EnvVars";
import ProductEnum from "../../enums/ProductEnum";
import CallTimer from "../CallTimer";
import TwilioVideoCallComponentProps from "./TwilioVideoCallComponentProps";
import { connectVideoCall, endVideoCall } from "../../redux/VideoCallSlice";
import { SentryHelper_captureException } from "../../helpers/SentryHelper";
import LocalizedStrings from "../../localizations/LocalizedStrings";
import { AnalyticsHelper_logEvent } from "../../helpers/firebase/AnalyticsHelper";
import { Alert_close, Alert_show } from "../../helpers/AlertHelper";

const Container = styled.div`
  display: flex;
  position: relative;
  flex: 1;
`;

const LocalTrack = styled.div<{ isSpeaking: boolean; bottomInsets?: number }>`
  background: silver;
  display: flex;
  position: absolute;
  right: 10px;
  max-height: 200px;
  max-width: 150px;
  height: 30%;
  width: 30%;
  border: ${({ isSpeaking }) =>
    isSpeaking ? "5px solid #027a48" : "5px solid black"};
  border-radius: 4px;
  bottom: ${EnvVars.REACT_APP_PRODUCT === ProductEnum.RemoteIQ
    ? "10px"
    : ({ bottomInsets }) => (bottomInsets ? bottomInsets + 60 + "px" : "60px")};
`;

const WaitingText = styled.div`
  display: flex;
  flex: 1;
  font-family: "Inter";
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  height: 100%;
  background-color: black;
`;

const CallTimerContainer = styled.div<{ bottomInsets?: number }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: ${({ bottomInsets }) =>
    bottomInsets ? bottomInsets + "px" : "10px"};
  left: 5px;
`;

const ParticipantListContainer = styled.div<{ bottomInsets?: number }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: ${EnvVars.REACT_APP_PRODUCT === ProductEnum.RemoteIQ
    ? "30px"
    : ({ bottomInsets }) => (bottomInsets ? bottomInsets + 60 + "px" : "60px")};
  left: 5px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const StyledTypography = styled(Typography)`
  font-family: "Inter";
  text-shadow: 1px 1px 2px black;
`;

const StyledProgressContainer = styled("div")`
  display: flex;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  align-items: center;
  justify-content: center;
`;

let startCallTime: DateTime;
let mountedScreenTime: DateTime;

let blurBackground = null;
if (process.env.JEST_WORKER_ID === undefined) {
  // We need to disable this on JEST because it cannot fetch the assets.
  blurBackground = new GaussianBlurBackgroundProcessor({
    assetsPath: "/twilio/",
    maskBlurRadius: 10,
    blurFilterRadius: 5,
    pipeline: Pipeline.WebGL2,
    debounce: true
  });
  blurBackground.loadModel();
}

const ParticipantIdentity = ({
  participant
}: {
  participant: TwilioParticipant;
}) => {
  const identityArray = participant?.identity?.split(";");
  return (
    <Row>
      <StyledTypography variant="body1" color="white">
        {identityArray[1]}
      </StyledTypography>
      {identityArray[2] === "false" && (
        <img src={CopilotIQLogo} style={COPILOT_LOGO_STYLE} alt="CopilotIQ" />
      )}
    </Row>
  );
};

const TwilioVideoCallComponent = forwardRef<
  TwilioVideoCallHandleType,
  TwilioVideoCallComponentProps
>(({ member_id, staff_id, onConnected, bottomInsets }, ref) => {
  const [localVideoPublication, setLocalVideoPublication] =
    useState<Video.LocalVideoTrackPublication>(null);
  const [room, setRoom] = useState<Video.Room>(null);
  const [remoteParticipants, setRemoteParticipants] = useState<
    Video.Participant[]
  >([]);
  const [dominantSpeaker, setDominantSpeaker] =
    useState<RemoteParticipant>(null);
  const [sinkId, setSinkId] = useState<string>();
  const [isBlurEnabled, setLocalBlurEnabled] = useState<boolean>(false);
  const [isSpeaking, setSpeaking] = useState<boolean>(false);
  const [isAudioEnabledState, setAudioEnabledState] = useState<boolean>(true);
  const [showParticipantList, setShowParticipantList] =
    useState<boolean>(false);

  const [isConnected, setConnected] = useState<boolean>(false);
  const [isConnecting, setConnecting] = useState<boolean>(false);

  const { startDate, connectedDate } = useSelector(
    (state: RootState) => state.videoCall
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (connectedDate === null && remoteParticipants.length > 0) {
      dispatch(connectVideoCall());
    }
  }, [connectedDate, remoteParticipants]);

  useEffect(() => {
    if (room === null) return;

    let streamDestroy = null;
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: false
      })
      .then((stream) => {
        streamDestroy = stream;
        const audioContext = new AudioContext();
        const mediaStreamAudioSourceNode =
          audioContext.createMediaStreamSource(stream);
        const analyserNode = audioContext.createAnalyser();
        mediaStreamAudioSourceNode.connect(analyserNode);

        const pcmData = new Float32Array(analyserNode.fftSize);
        const onFrame = () => {
          analyserNode.getFloatTimeDomainData(pcmData);
          let sumSquares = 0.0;
          for (const amplitude of pcmData) {
            sumSquares += amplitude * amplitude;
          }
          const value = Math.sqrt(sumSquares / pcmData.length);
          if (value > 0.01) setSpeaking(true);
          else setSpeaking(false);
          window.requestAnimationFrame(onFrame);
        };
        window.requestAnimationFrame(onFrame);
      })
      .catch((error) => {
        SentryHelper_captureException(error);
        AnalyticsHelper_logEvent("VideoCall_ErrorMediaDevices", {
          error: JSON.stringify(error),
          member_id,
          user_id: member_id,
          staff_id
        });
      });

    return () => {
      streamDestroy?.getTracks().forEach((track) => {
        track.stop();
      });
    };
  }, [room]);

  const { request, release } = useWakeLock();
  useEffect(() => {
    request();
    return () => release();
  }, []);

  const { mainParticipant } = useMemo(() => {
    if (remoteParticipants.length === 0) return {};
    if (dominantSpeaker === null) {
      const dominantSpeaker = remoteParticipants[0];
      return {
        mainParticipant: dominantSpeaker,
        otherParticipants: remoteParticipants.filter(
          (item) => item.sid !== dominantSpeaker.sid
        )
      };
    }

    return {
      mainParticipant: dominantSpeaker,
      otherParticipants: remoteParticipants.filter(
        (item) => item.sid !== dominantSpeaker.sid
      )
    };
  }, [remoteParticipants, dominantSpeaker]);

  const getDevices = () => {
    return new Promise<MediaDeviceInfo[]>(async (resolve) => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      resolve(devices);
    });
  };

  const setDevice = async (
    deviceId: string,
    kind: MediaDeviceKind,
    retryCount = 0
  ) => {
    if (room === null) return;
    switch (kind) {
      case "audioinput":
        const track = await createLocalAudioTrack({
          deviceId: { exact: deviceId }
        });
        room.localParticipant.audioTracks.forEach((publication) => {
          publication.track.stop();
          room.localParticipant.unpublishTrack(publication.track);
        });
        room.localParticipant.publishTrack(track).catch((error) => {
          const errorString =
            typeof error === "string" ? error : JSON.stringify(error);

          SentryHelper_captureException(error);
          AnalyticsHelper_logEvent("VideoCall_ErrorPublishAudioTrack", {
            error: errorString,
            member_id,
            user_id: member_id,
            staff_id,
            retryCount
          });
          //Retry in 2 seconds
          setTimeout(() => {
            if (retryCount < 10) setDevice(deviceId, kind, retryCount + 1);
          }, 2000);
        });
        break;
      case "audiooutput":
        setSinkId(deviceId);
        break;
      case "videoinput":
        setVideoEnabled(false);
        createLocalVideoPublication(deviceId);
        break;
    }
  };

  const setVideoEnabled = (enabled: boolean, deviceId?: string) => {
    if (localVideoPublication && enabled) return;
    if (localVideoPublication === null && !enabled) return;

    if (enabled) {
      createLocalVideoPublication(deviceId);
    } else {
      localVideoPublication.track.stop();
      localVideoPublication.track.disable();
      localVideoPublication.unpublish();

      setLocalVideoPublication(null);
    }
  };

  const setAudioEnabled = (enabled: boolean) => {
    setAudioEnabledState(enabled);
    room?.localParticipant?.audioTracks.forEach((publication) => {
      if (enabled) publication.track.enable();
      else publication.track.disable();
    });
  };

  const toggleSoundSetup = (enabled: boolean) => {};

  const setBlurIfNeeded = (track: LocalVideoTrack, enabled: boolean) => {
    if (blurBackground === null) return;
    if (track === undefined) return;
    if (enabled && track.processor === null) {
      track.addProcessor(blurBackground, {
        inputFrameBufferType: "video",
        outputFrameBufferContextType: "webgl2"
      });
    }
    if (!enabled && track.processor !== null) {
      track.removeProcessor(blurBackground);
    }
  };

  const setBlurEnabled = (enabled: boolean) => {
    const localVideoTrack = localVideoPublication?.track;
    setBlurIfNeeded(localVideoTrack, enabled);
    setLocalBlurEnabled(enabled);
  };

  const setShowParticipants = (enabled: boolean) => {
    setShowParticipantList(enabled);
  };

  useImperativeHandle(ref, () => {
    return {
      connect: (access_token: string, member_id: string) => {
        if (room !== null || isConnected || isConnecting) return;

        setConnecting(true);
        Video.connect(access_token, {
          dominantSpeaker: true,
          preferredVideoCodecs: ["VP8", "H264"]
        })
          .then((room: Video.Room) => {
            setRoom(room);
            setConnected(true);
            setConnecting(false);
            onConnected();

            startCallTime = DateTime.now();
            AnalyticsHelper_logEvent("VideoCall_Start", {
              room_name: room?.name,
              loading_time_seconds:
                mountedScreenTime &&
                DateTime.now().toSeconds() - mountedScreenTime.toSeconds(),
              member_id,
              user_id: member_id,
              staff_id
            });
            mountedScreenTime = null;
          })
          .catch((error) => {
            SentryHelper_captureException(error);
            console.log(error);
            setConnecting(false);
            AnalyticsHelper_logEvent("VideoCall_Error", {
              error: JSON.stringify(error),
              member_id,
              user_id: member_id,
              staff_id
            });

            Alert_show({
              dispatch,
              id: "video_call_error",
              title: "Video Call Error",
              content: "Could not join the video call",
              buttons: [
                {
                  text: "Close",
                  onPress: () =>
                    Alert_close({ dispatch, id: "video_call_error" })
                }
              ]
            });
            dispatch(endVideoCall());
          });
      },
      disconnect: () => {
        if (room === null) return;

        AnalyticsHelper_logEvent("VideoCall_End", {
          room_name: room.name,
          duration_seconds:
            startCallTime &&
            DateTime.now().toSeconds() - startCallTime.toSeconds(),
          member_id,
          user_id: member_id,
          staff_id
        });
        startCallTime = null;

        setAudioEnabled(false);
        setVideoEnabled(false);

        room?.localParticipant?.audioTracks.forEach((publication) => {
          publication.unpublish();
        });

        room.disconnect();
        setRoom(null);
        setConnected(false);
      },
      getDevices,
      setDevice,
      setAudioEnabled,
      setVideoEnabled,
      toggleSoundSetup,
      setBlurEnabled,
      setShowParticipants,
      flipCamera: () => {} // Disabled on Web for now
    };
  });

  const createLocalVideoPublication = (deviceId?: string) => {
    Video.createLocalVideoTrack({
      deviceId,
      // https://twilio.github.io/twilio-video-processors.js/classes/GaussianBlurBackgroundProcessor.html
      frameRate: 24,
      width: 640,
      height: 480
    })
      .then(async (localVideoTrack) => {
        setBlurIfNeeded(localVideoTrack, isBlurEnabled);
        return room.localParticipant.publishTrack(localVideoTrack);
      })
      .then((publication: Video.LocalVideoTrackPublication) => {
        setLocalVideoPublication(publication);
      })
      .catch((error) => {
        console.log(error);
        const errorString =
          typeof error === "string" ? error : JSON.stringify(error);

        SentryHelper_captureException(error);
        AnalyticsHelper_logEvent("VideoCall_ErrorPublishVideoTrack", {
          error: errorString,
          member_id,
          user_id: member_id,
          staff_id
        });
      });
  };

  const onParticipantConnected = (participant: Video.Participant) => {
    console.log(`${participant.identity} has joined the room.`);

    setRemoteParticipants((previousInputs) => {
      const filteredElements = previousInputs.filter(
        (item) => item.sid !== participant.sid
      );
      return [...filteredElements, participant];
    });
  };

  function onParticipantDisconnected(participant: Video.Participant) {
    console.log(`${participant.identity} has left the room`);

    if (dominantSpeaker?.sid === participant.sid) setDominantSpeaker(null);

    setRemoteParticipants((previousInputs) => {
      const filteredElements = previousInputs.filter(
        (item) => item.sid !== participant.sid
      );
      return [...filteredElements];
    });
  }

  function onDominantSpeakerChanged(participant: Video.RemoteParticipant) {
    console.log(`onDominantSpeakerChanged: ${participant?.identity}`);

    setDominantSpeaker(participant);
  }

  // https://github.com/twilio/twilio-video-app-react/blob/master/src/components/Publication/Publication.tsx
  useEffect(() => {
    if (room === null) return;

    setRemoteParticipants([]);
    room.participants.forEach(onParticipantConnected);
    room.on("participantConnected", onParticipantConnected);
    room.on("participantDisconnected", onParticipantDisconnected);
    room.on("dominantSpeakerChanged", onDominantSpeakerChanged);
    room.once("disconnected", (error) => {
      room.participants.forEach(onParticipantDisconnected);
    });
  }, [room]);

  useEffect(() => {
    if (room === null) return;
    const videoPublications = Array.from(
      room.localParticipant.videoTracks.values()
    );
    if (videoPublications.length > 0) {
      setBlurIfNeeded(videoPublications[0].track, isBlurEnabled);
      setLocalVideoPublication(videoPublications[0]);
    }
  }, [room]);

  useEffect(() => {
    // This works only on Desktop browsers
    window.onbeforeunload = confirmExit;
    function confirmExit() {
      return LocalizedStrings.videoCall.areYouSureYouWantToLeave;
    }
    return () => (window.onbeforeunload = undefined);
  }, []);

  const waitingText =
    EnvVars.REACT_APP_PRODUCT === ProductEnum.RemoteIQ
      ? LocalizedStrings.videoCall.waitingForMemberToJoin
      : LocalizedStrings.videoCall.waitingForCarerToJoin;

  return (
    <Container>
      {mainParticipant ? (
        <Participant
          participant={mainParticipant}
          sinkId={sinkId}
          showParticipantList={showParticipantList}
        />
      ) : (
        <WaitingText>{waitingText}</WaitingText>
      )}

      {localVideoPublication && (
        <LocalTrack
          isSpeaking={isSpeaking && isAudioEnabledState}
          bottomInsets={bottomInsets}
        >
          <Track track={localVideoPublication.track} isLocal={true} />
        </LocalTrack>
      )}

      {showParticipantList && (
        <ParticipantListContainer bottomInsets={bottomInsets}>
          <StyledTypography variant="h4" color="white">
            Participants
          </StyledTypography>
          {room && <ParticipantIdentity participant={room.localParticipant} />}
          {remoteParticipants?.map((participant) => (
            <ParticipantIdentity
              participant={participant}
              key={participant.identity}
            />
          ))}
        </ParticipantListContainer>
      )}

      {EnvVars.REACT_APP_PRODUCT === ProductEnum.RemoteIQ && (
        <CallTimerContainer bottomInsets={bottomInsets}>
          {startDate != null && <CallTimer />}
        </CallTimerContainer>
      )}

      {!isConnected && (
        <StyledProgressContainer>
          <CircularProgress sx={{ marginTop: 20 }} color="primary" />
        </StyledProgressContainer>
      )}
    </Container>
  );
});

const COPILOT_LOGO_STYLE = { width: 16, height: 16 };

export default TwilioVideoCallComponent;
